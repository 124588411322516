import Layout from '~/components/layout/Layout';
import type { LoaderFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import { fetchStrapi } from '~/lib/server/api.server';
import type { BlockComponent, LandingPage } from '@cardo/types';
import type { MetaFunction } from '@remix-run/react';
import { useLoaderData } from '@remix-run/react';
import { Blocks, Block } from '~/components/blocks/BlockManager';
import {
  generateMetaFromSeoComponent,
  generateOgUrlMetaDescriptor,
} from '~/lib/meta';
import { cn } from '@cardo/lib';
import { generateAppUrl } from '~/lib/utils';

export function headers({ loaderHeaders }: { loaderHeaders: Headers }) {
  return {
    'cache-control': loaderHeaders.get('cache-control'),
  };
}

export const loader: LoaderFunction = async () => {
  const res = await fetchStrapi<LandingPage>('/landing-page', {
    populate: 'deep,5',
  });
  return json(res.data, {
    headers: {
      'cache-control':
        'max-age=1, s-maxage=604800, stale-while-revalidate=31536000',
    },
  });
};

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  return [
    ...generateMetaFromSeoComponent(data?.attributes.seo),
    generateOgUrlMetaDescriptor(location),
    {
      tagName: 'link',
      rel: 'canonical',
      href: generateAppUrl(location.pathname),
    },
    { title: 'Cardonomics - Home' },
  ];
};

type HeroProps = {
  heading: string;
  subheading?: string;
  block?: BlockComponent | null;
};

function Hero({ heading, subheading, block }: HeroProps) {
  return (
    <div className="flex h-full w-full flex-grow flex-col items-center justify-between py-12 sm:py-20">
      <h1
        className={cn(
          'text-center text-4xl sm:text-left md:text-5xl lg:text-6xl',
          subheading && 'mb-4',
          !subheading && 'mb-16'
        )}
      >
        {heading}
      </h1>
      {subheading && (
        <p className="mb-8 w-4/5 text-center sm:w-1/2 sm:text-xl">
          {subheading}
        </p>
      )}
      {block && (
        <div className="flex w-full flex-grow items-center justify-center">
          <Block block={block} />
        </div>
      )}
    </div>
  );
}

export default function Index() {
  const data = useLoaderData<typeof loader>();

  const heroBlock =
    data?.attributes.hero && data?.attributes.hero?.length > 0
      ? data.attributes.hero[0]
      : null;

  return (
    <Layout
      hero={
        data?.attributes.heading ? (
          <Hero
            heading={data?.attributes.heading}
            subheading={data?.attributes.subheading}
            block={heroBlock}
          />
        ) : null
      }
      heroContainerClassName="h-fit"
    >
      <div className="mx-auto flex w-full flex-col space-y-12 2xl:w-4/5">
        {data?.attributes.blocks && data?.attributes.blocks.length > 0 && (
          <Blocks blocks={data?.attributes.blocks} />
        )}
      </div>
    </Layout>
  );
}
